import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { uniqBy } from "lodash";

import address from "./address";
import auth from "./auth";
import scheduling from "./scheduling";

export default new InMemoryCache({
  typePolicies: {
    Viewer: {
      keyFields: [],
    },
    AvailabilityProviderDetails: {
      keyFields: ({ athenaId, shortAddress }) => `${athenaId}:${shortAddress}`,
    },
    Address: {
      keyFields: ({ city, line1, line2, postalCode, state }) =>
        `${city}:${line1}:${line2}:${postalCode}:${state}`,
    },
    NotificationPreference: {
      keyFields: [],
    },
    User: {
      keyFields: [],
    },
    UserAccount: {
      keyFields: [],
    },
    Query: {
      fields: {
        address,
        auth,
        scheduling,
        availabilities: {
          keyArgs: [
            "daysOfWeek",
            "flowSessionId",
            "gender",
            "language",
            "maxStartDate",
            "maxStartTime",
            "minStartDate",
            "minStartTime",
            "geo",
            "calendarIds",
            "firstAvailableDatePerCalendarOnly",
          ],
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }

            const allPages = [...existing.pages, ...incoming.pages];
            // Combine pages to ensure only one entry per date.
            // This is important when fetching the last page or refreshing.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newPages: any[] = [];
            const dateMap = new Map();
            for (const page of allPages) {
              if (!dateMap.has(page.date)) {
                dateMap.set(page.date, true);
              }
              newPages.push(page);
            }

            newPages = uniqBy(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              newPages as any[],
              (item) => `${item?.providerDetails?.__ref},${item?.date}`,
            );

            return { pages: newPages, pageInfo: incoming.pageInfo };
          },
        },
      },
    },
    Patient: {
      keyFields: [],
      fields: {
        appointments: relayStylePagination(["past", "startDate", "endDate"]),
        diagnosticReports: relayStylePagination(["categories", "endDate", "sort", "startDate"]),
        providers: {
          merge: (_existing, incoming) => incoming,
        },
      },
    },
    CedarBillingAccount: {
      keyFields: ["patientMrn"],
      fields: {
        summary: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        invoices: {
          // keyArgs keeps values returned for different queries separate.
          // That is, querying for `invoiceState: [PENDING, READY, PLAN,
          // PARTIAL]` vs `[COMPLETE]`.
          keyArgs: ["invoiceState"],
          // The data returned isn't the exact shape expected by Apollo's
          // `offsetLimitPagination`, but this function is based on it:
          // https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L28
          merge(existing, incoming, { args }) {
            // If no offset is provided, just replace any existing data
            if (args && args.offset === undefined) {
              return incoming;
            }

            // Merge existing and incoming data
            const merged = existing ? existing.data.slice(0) : [];
            const start = args ? args.offset : merged.length;
            const end = start + incoming.data.length;
            for (let i = start; i < end; ++i) {
              merged[i] = incoming.data[i - start];
            }

            // Return incoming properties with merged `data` key from above
            return { ...incoming, data: merged };
          },
        },
      },
    },
  },
});
