import { useReactiveVar } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { auth } from "/apollo/client/local";
import routes from "/constant/url.constant";
import { hasFeatureFlagEnabled } from "/util/featureFlags/featureFlags";

import CareSummariesB2C from "./page/CareSummariesB2C";
import DocumentsB2C from "./page/DocumentsB2C";
import Immunization from "./page/Immunization";
import ImmunizationsB2C from "./page/ImmunizationsB2C";
import MedicationB2C from "./page/MedicationB2C";
import MedicationsB2C from "./page/MedicationsB2C";
import MyHealthIndex from "./page/MyHealthIndex/";
import PharmacySearchB2C from "./page/PharmacySearchB2C";
import PreferredPharmacyB2C from "./page/PreferredPharmacyB2C";
import ResultB2C from "./page/ResultB2C";
import ResultsB2C from "./page/ResultsB2C";

const MyHealthRoutes = ({ match }: RouteComponentProps) => {
  const { isAuthenticated } = useReactiveVar(auth.var);
  return (
    <Switch>
      <Route exact path={match.url} component={MyHealthIndex} />
      {isAuthenticated ? (
        <Switch>
          {hasFeatureFlagEnabled("medications_enabled") && (
            <Route exact path={routes.medication} component={MedicationB2C} />
          )}
          {hasFeatureFlagEnabled("medications_enabled") && (
            <Route exact path={routes.medications} component={MedicationsB2C} />
          )}
          {hasFeatureFlagEnabled("medications_enabled") && (
            <Route exact path={routes.preferredPharmacy} component={PreferredPharmacyB2C} />
          )}
          {hasFeatureFlagEnabled("medications_enabled") && (
            <Route exact path={routes.pharmacySearch} component={PharmacySearchB2C} />
          )}
          {hasFeatureFlagEnabled("documents_enabled") && (
            <Route exact path={routes.documents} component={DocumentsB2C} />
          )}
          {hasFeatureFlagEnabled("care_summaries_enabled") && (
            <Route exact path={routes.careSummaries} component={CareSummariesB2C} />
          )}
          {hasFeatureFlagEnabled("test_results_enabled") && (
            <Route exact path={routes.results} component={ResultsB2C} />
          )}
          {hasFeatureFlagEnabled("test_results_enabled") && (
            <Route exact path={routes.result} component={ResultB2C} />
          )}
          {hasFeatureFlagEnabled("immunization_enabled") && (
            <Route exact path={routes.immunizations} component={ImmunizationsB2C} />
          )}
          {hasFeatureFlagEnabled("immunization_enabled") && (
            <Route exact path={routes.immunizationDetails} component={Immunization} />
          )}
        </Switch>
      ) : (
        <Redirect to={`${match.url}`} />
      )}
    </Switch>
  );
};

export default MyHealthRoutes;
