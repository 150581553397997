export type FeatureFlagKey =
  | "documents_enabled"
  | "care_summaries_enabled"
  | "immunization_enabled"
  | "insurance_enabled"
  | "personal_info_enabled"
  | "test_results_enabled"
  | "medications_enabled"
  | "signup_slayer_enabled";

export enum FeatureStatus {
  Enabled = "true",
  Disabled = "false",
  Legacy = "legacy",
}
