import "/component/provider/ThemeProvider/globalStyles.css";
import "/util/firebase.util";

import { ApolloProvider } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import awsdk from "awsdk";
import React, { Fragment, StrictMode, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { hot } from "react-hot-loader/root";

import apollo from "/apollo/client";
import { Loading } from "/component/base";
import AmwellProvider from "/component/provider/AmwellProvider";
import { AnalyticsProvider } from "/component/provider/AnayticsProvider";
import ModalProvider from "/component/provider/ModalProvider";
import RedirectListenerProvider from "/component/provider/RedirectListenerProvider";
import ThemeProvider from "/component/provider/ThemeProvider";
import ToastProvider from "/component/provider/ToastProvider";
import { initCountly } from "/util/countly";
import { getUtmParamsFromUrl } from "/util/url";

import CaregiverProvider from "../../provider/CaregiverProvider";
import MsalProvider from "./component/MsalProvider";
import RouterProvider from "./component/RouterProvider";
import Routes from "./component/Routes";
import { stripeOptions, stripePromise } from "./stripe";

const isDevelopment = process.env.NODE_ENV === "development";
const RootNode = isDevelopment ? StrictMode : Fragment;
const sdk = new awsdk.AWSDK();
initCountly();
getUtmParamsFromUrl();

export const AppRoot = (): JSX.Element => (
  <RootNode>
    <RedirectListenerProvider>
      <ThemeProvider>
        <Suspense fallback={<Loading />}>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <RouterProvider>
              <MsalProvider>
                <AnalyticsProvider>
                  <ModalProvider>
                    <ToastProvider>
                      <ApolloProvider client={apollo}>
                        <AmwellProvider sdk={sdk}>
                          <CaregiverProvider>
                            <HelmetProvider>
                              <Routes />
                            </HelmetProvider>
                          </CaregiverProvider>
                        </AmwellProvider>
                      </ApolloProvider>
                    </ToastProvider>
                  </ModalProvider>
                </AnalyticsProvider>
              </MsalProvider>
            </RouterProvider>
          </Elements>
        </Suspense>
      </ThemeProvider>
    </RedirectListenerProvider>
  </RootNode>
);

export default hot(AppRoot);
