import summitHealthLogoNew from "/asset/image/summitHealthLogoNew.png";

export const replaceHtmlImgTag = (htmlString: string): string => {
  const imgIndexStart = htmlString.indexOf("<img");
  if (imgIndexStart >= 0) {
    const imgIndexEnd = htmlString.indexOf(">", imgIndexStart);
    const img = `<img id='letterhead' src='${summitHealthLogoNew}' border='0' height='0' width='auto' />`;
    htmlString =
      htmlString.substring(0, imgIndexStart) + img + htmlString.substring(imgIndexEnd + 1);
    htmlString = htmlString.replace("â¢", "&#8226;");
  }
  return htmlString;
};
