import { gql, MutationHookOptions } from "@apollo/client";

import { useB2CMutation } from "../client";
import { CreatePatient, CreatePatientVariables } from "../schema";

const CREATE_PATIENT = gql`
  mutation CreatePatient($input: CreateUserInput!) {
    patient {
      signup(demographics: $input) {
        success
      }
    }
  }
`;

export const useCreatePatientMutation = (
  options?: MutationHookOptions<CreatePatient, CreatePatientVariables>,
) => useB2CMutation<CreatePatient, CreatePatientVariables>(CREATE_PATIENT, options);
