import gql from "graphql-tag";
import { groupBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Loading } from "/component/base";
import ProviderAvailabilitiesListItem from "/component/partial/ProviderAvailabilitiesListItem";
import { layout } from "/styles";

import SectionHeader from "../SectionHeader";
import { Props } from "./ProviderAvailabilitiesList.types";

const ProviderAvailabilitiesList = ({
  className,
  availabilities,
  availabilitiesDisabled = false,
  onTimeslotClick,
  fetchMore,
}: Props) => {
  // We can't be guaranteed how many results we get back when we query experian, so we might need to manually
  // call `fetchMore` to get more pages of results to ensure that our list goes all the way to the bottom of
  // the screen (needed for `InfiniteScroll` to work).
  const [manuallyLoadingMore, setManuallyLoadingMore] = useState(true);

  const calendars = availabilities?.pages || [];
  const nextCursor = availabilities?.pageInfo?.nextCursor;
  const scrollRef = useRef<HTMLUListElement | null>(null);

  const groups = groupBy(calendars, "date");

  const sections = Object.keys(groups).sort();

  const getNextPage = () => {
    fetchMore({
      variables: {
        after: nextCursor,
      },
    });
  };

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const listRect = scrollRef.current?.getBoundingClientRect();

    // Figure out if we need to manually call `getNextPage` to ensure that we have results all
    // the way to the bottom of the screen. If there aren't enough results, and <body> isn't scrollable,
    // InfiniteScroll won't be able to trigger `fetchMore`s.
    if (
      listRect &&
      listRect.height + listRect.top < windowHeight &&
      calendars?.length &&
      nextCursor
    ) {
      getNextPage();
    } else {
      setManuallyLoadingMore(false);
    }
  }, [calendars, nextCursor]);

  return (
    <InfiniteScroll
      hasChildren
      dataLength={calendars.length}
      hasMore={!!nextCursor}
      loader={<Loading variant="list" />}
      next={getNextPage}
      className={className}
    >
      <ul css={layout.spacedChildrenVertical("gutter")} ref={scrollRef}>
        {sections.map((date) => (
          <li key={date}>
            <SectionHeader date={date} />
            <ul>
              {groups[date].map((availability) => (
                <li key={availability.calendarId}>
                  <ProviderAvailabilitiesListItem
                    availabilitySlotsDisabled={availabilitiesDisabled}
                    availability={availability}
                    onClickAvailabilitySlot={onTimeslotClick}
                  />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      {!!manuallyLoadingMore && <Loading variant="list" />}
    </InfiniteScroll>
  );
};

export default ProviderAvailabilitiesList;

export const ProviderAvailabilitiesListItemFragment = gql`
  fragment ProviderAvailabilitiesListItemFragment on Availability {
    date
    calendarId
    timeSlots {
      appointmentTypeId
      availabilityId
      calendarId
      date
      startTime
      endTime
      startAtUtc
      endAtUtc
    }
    providerDetails {
      id
      athenaId
      photoUrl
      nameWithDesignations
      primarySpecialtyName
      shortAddress
      latitude
      longitude
      firstName
      lastName
    }
  }
`;

export const ProviderAvailabilitiesListFragment = gql`
  fragment ProviderAvailabilitiesListFragment on Query {
    availabilities(
      after: $after
      daysOfWeek: $daysOfWeek
      flowSessionId: $flowSessionId
      gender: $gender
      language: $language
      maxStartTime: $maxStartTime
      minStartDate: $minStartDate
      minStartTime: $minStartTime
      geo: $geo
      firstAvailableDatePerCalendarOnly: false
      calendarIds: $calendarIds
    ) {
      pageInfo {
        nextCursor
      }
      pages {
        ...ProviderAvailabilitiesListItemFragment
      }
    }
  }

  ${ProviderAvailabilitiesListItemFragment}
`;
