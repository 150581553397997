const routes = {
  // Internal Routes
  appointments: "/appointments",
  root: "/home",
  myHealth: "/my-health",
  account: "/account",
  scheduling: "/scheduling",
  telehealth: "/telehealth",
  urgentCare: "/urgent-care",
  signUp: "/signUp",

  // My Health sub routes
  result: "/my-health/results/:id",
  results: "/my-health/results",
  medication: "/my-health/medications/:id",
  medications: "/my-health/medications",
  prescription: "/my-health/prescriptions/:prescriptionId",
  prescriptions: "/my-health/prescriptions",
  preferredPharmacy: "/my-health/pharmacy",
  pharmacySearch: "/my-health/pharmacy-search",
  careSummaries: "/my-health/care-summaries",
  document: "/my-health/documents/:id",
  documents: "/my-health/documents",
  documentDetails: "/my-health/documents/visit",
  immunizations: "/my-health/immunizations",
  immunizationDetails: "/my-health/immunizations/:immunizationId",

  // Account sub routes
  personalInformation: "/account/personal-information",
  billingAccounts: "/account/billing-accounts",
  billingAccountInvoices: "/invoices",
  insurances: "/account/insurance",
  messaging: "/account/messages",
  sentMessages: "/account/messages/sent",
  notifications: "/account/notifications",
  caregiverNotifications: "/account/notifications/caregivers",
  confirmCaregiver: "/caregiverAccess/confirm",
  confirmCaregiverAccess: "/caregiverAccess/confirm/:id",
  InviteToCaregiverAccess: "/caregiverAccess/invite/:id",
  caregiver: "/account/caregiver",
  caregiverDetails: "/account/caregiver/details",
  settings: "/account/settings",
  feedback: "/account/feedback",

  // Scheduling sub routes
  availabilities: "/availabilities",
  availabilitiesSelected: "/availabilities/selected",
  decisionSupport: "/decision-support",
  visitReason: "/visit-reason",
  confirm: "/confirm",
  confirmed: "/confirmed",
  moreService: "/more-service",

  // Telehealth Sub routes
  telehealthIntro: "/telehealth/intro",
  telehealthLocationVerification: "/telehealth/locationVerification",
  telehealthPatientSelection: "/telehealth/patientSelection",
  telehealthPrevisit: "/telehealth/previsit",
  telehealthProviders: "/telehealth/providers",
  telehealthWaitingRoom: "/telehealth/waitingRoom",

  demographics: "/signup",
  contactUs: "/contact-us",
  faq: "/faq",
  faqCategory: "/category",
  faqDetail: "/detail",

  passwordReset: "/passwordReset",
  accountConnectionError: "/accountError",

  // External Routes
  marketing_site: "https://summit-citymd.com/",
  googleMapUri: "https://www.google.com/maps/search/?api=1&query=",
} as const;

/**
 * `homeRoutes` is a list of the routes for which we want the Home tab to be active
 */
export const homeRoutes = [
  routes.root,
  routes.scheduling,
  routes.telehealth,
  routes.urgentCare,
  routes.appointments,
];

type ValueOf<T> = T[keyof T];
export type Route = ValueOf<typeof routes>;

export default routes;
