import styled from "styled-components";

export const Base = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `1fr min(80ch, calc(100% - ${theme.spacing.expanded}px)) 1fr`,
  gridColumnGap: theme.spacing.standard,
  height: "100%",
}));

export const Content = styled.div`
  place-self: center;
  grid-column: 2;
  margin-top: -50%;
`;

export const Header = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: theme.spacing.standard,
}));

export const Body = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing.standard,
}));
