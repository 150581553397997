import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { LinkProps as RouterLinkerProps, RouteComponentProps, useLocation } from "react-router-dom";

import { scheduling } from "/apollo/client/local";
import { GetDecisionSupportIndex_decisionSupportFlows as DSFlow } from "/apollo/schema/types";
import { List, Loading } from "/component/base";
import { FeatherIcon } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import SetCaregiver from "/component/partial/SetCaregiver";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { useToastContext } from "/component/provider/ToastProvider";
import routes from "/constant/url.constant";
import { useAuth, useMounted, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import {
  CtaCard,
  FlowContainer,
  HeaderContainer,
  InlineMessage,
  ItemContent,
} from "./SchedulingIndex.styles";
import useQueryDecisionSupportFlows from "./useQueryDecisionSupportFlow";

type DSFlowLinkItem = DSFlow & Pick<RouterLinkerProps, "to">;

const addRoute = (flow: DSFlow): DSFlowLinkItem => {
  const experianUrl = flow.experianUrl ?? "";
  return {
    ...flow,
    to: flow.useExperianUrl
      ? experianUrl
      : `${routes.scheduling}${routes.decisionSupport}/${flow.id}`,
  };
};

const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const SchedulingIndex = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("scheduling");

  const { isAuthenticated } = useAuth();
  useEffect(() => {
    scheduling.updateAnalytics(AnalyticsUserFlow.SCHEDULE_VISIT);
  }, []);

  const location = useLocation<{ bookingExpired: boolean }>();
  const { data, error, loading, refetch: decisionSupportRefetch } = useQueryDecisionSupportFlows();
  const { caregiver, caregiverMode } = useCaregiverProvider();
  const flows = data?.decisionSupportFlows?.map(addRoute);
  flows?.push(
    addRoute({
      __typename: "DecisionSupportFlow",
      id: "n/a",
      title: "Something else?",
      experianUrl: `${routes.scheduling}${routes.moreService}`,
      useExperianUrl: true,
    }),
  );
  const { showToast } = useToastContext();
  const mountedRef = useMounted();

  useEffect(() => {
    decisionSupportRefetch();
  }, [caregiver.id]);

  const handleFlowClicked = (flow: DSFlowLinkItem) => {
    logButtonClickEvent(flow.title);
    const route = flow.useExperianUrl
      ? flow.experianUrl
      : `${routes.scheduling}${routes.decisionSupport}/${flow.id}`;
    if (route) {
      if (flow.useExperianUrl) {
        history.push(route, flow);
      } else {
        history.push(route);
      }
    }
  };

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
      source: AnalyticsSource.DECISION_FLOW,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  useEffect(() => {
    if (mountedRef.current) {
      if (location?.state?.bookingExpired) {
        showToast({
          icon: "alert",
          message: t("bookingExpired", "Your booking reservation has expired. Please try again"),
          type: "error",
        });
      }
    }
  }, []);
  return (
    <MainContent
      parentPage="root"
      headerAccessory={
        <HeaderContainer>
          {ENABLE_CAREGIVER_PROXY && isAuthenticated && (
            <SetCaregiver
              buttonStyle="blank"
              analyticsParams={
                {
                  user_flow: AnalyticsUserFlow.CAREGIVER,
                  source: AnalyticsSource.DECISION_FLOW,
                } as ButtonClickParams
              }
            />
          )}
        </HeaderContainer>
      }
      pageTitle={
        caregiverMode ? t("screenTitleWithName", { name: caregiver.firstName }) : t("screenTitle")
      }
    >
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      {error && (
        <EmptyStateCta
          title={t("error.title")}
          subtitle={t("error.subtitle")}
          ctaText={t("error.cta")}
          onClickCta={() => history.push(routes.root)}
        />
      )}
      {loading && <Loading />}

      {data && (
        <>
          <InlineMessage variant="important" icon={<FeatherIcon name="alertCircle" />}>
            {t("emergencyDisclaimer")}
          </InlineMessage>
          {flows && (
            <FlowContainer>
              <List
                as="Buttons"
                hideLastChildBorder
                onClick={handleFlowClicked}
                data={flows}
                renderItem={(flow) => (
                  <ItemContent key={flow.id} variant="body1Bold" element="div">
                    {flow.title}
                  </ItemContent>
                )}
              ></List>
            </FlowContainer>
          )}

          <CtaCard
            title={t("faqCta.title")}
            subtitle={t("faqCta.subtitle")}
            links={[
              {
                title: t("faqCta.link"),
                to: routes.faq,
                analyticsParams: {
                  user_flow: AnalyticsUserFlow.SCHEDULE_VISIT,
                  source: AnalyticsSource.DECISION_FLOW,
                  button_name: "View FAQ",
                } as ButtonClickParams,
              },
            ]}
          />
        </>
      )}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, SchedulingIndex, undefined, "ai-tracking");
