import { useReactiveVar } from "@apollo/client";
import React from "react";

import { address } from "/apollo/client/local";
import ProviderItem from "/component/partial/ProviderItem";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import getDistance from "/util/distance.util";

import { Base, MapPin } from "./ProviderAvailabilitiesListItem.styles";
import { Props } from "./ProviderAvailabilitiesListItem.types";
import TimeSlots from "./TimeSlots";

const ProviderAvailabilitiesListItem = ({
  className,
  availability,
  onClickAvailabilitySlot,
  availabilitySlotsDisabled,
}: Props) => {
  const { t } = useTranslation("partial-providerAvailabilitiesListItem");
  const { providerDetails } = availability;

  const { lat, lng } = useReactiveVar(address.var);

  let miles: number;

  const { latitude: providerLat, longitude: providerLng } = availability.providerDetails;

  if (providerLat && providerLng && lat && lng) {
    const providerLatLng = { lat: providerLat, lng: providerLng };
    const myLatLng = { lat: lat, lng: lng };

    miles = getDistance(providerLatLng, myLatLng);
  }

  const renderProviderItem = () => (
    <ProviderItem
      title={providerDetails.nameWithDesignations}
      photoUrl={providerDetails.photoUrl}
      name={{ firstName: providerDetails.firstName, lastName: providerDetails.lastName }}
      subtitle={
        <div css={layout.spacedChildrenVertical(2)}>
          {providerDetails.primarySpecialtyName && (
            <div>{providerDetails.primarySpecialtyName}</div>
          )}
          {miles && <div>{t("milesAway", { miles })}</div>}
          {providerDetails.shortAddress && (
            <div css={layout.flexCenterHorizontal}>
              <MapPin size={16} />
              <div css={layout.lineClamp(1)}>{providerDetails.shortAddress}</div>
            </div>
          )}
        </div>
      }
    />
  );

  return (
    <Base className={className}>
      {renderProviderItem()}
      <TimeSlots
        availability={availability}
        onClickAvailabilitySlot={onClickAvailabilitySlot}
        disabled={availabilitySlotsDisabled}
      />
    </Base>
  );
};

export default ProviderAvailabilitiesListItem;
