import { ApolloError, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import CREATE_USER_MUTATION_GQL from "/apollo/mutation/createUser";
import { useCreatePatientMutation } from "/b2c/mutation/useCreatePatientMutation.b2c";
import { AdministrativeSex, StateCode } from "/b2c/schema";
import { Button, CheckBox, Link } from "/component/base";
import Alert from "/component/base/Alert";
import { Eye, EyeOff } from "/component/base/Icon";
import FormattedTextField from "/component/partial/formik/FormattedTextField";
import PickerField from "/component/partial/formik/PickerField";
import TextField from "/component/partial/formik/TextField";
import { SignUpFormType } from "/constant/signUp.constant";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";
import { layout } from "/styles";
import { formatDate, minAgeTest } from "/util/date";
import { hasFeatureFlagEnabled } from "/util/featureFlags/featureFlags";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";
import { encryptPassword } from "/util/password";

import AddressForm from "../../AddressForm";
import { FormContentContainer, Title } from "../SignUpForms.styles";
import { SignUpFormProps } from "../SignUpForms.types";
import PasswordValidations from "./PasswordValidations";

const TERMS_LINK = process.env.TERMS_LINK;

interface SubmitProps {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  password: string;
  linkedPhoneNumber: string;
  legalSex: string;
  address: {
    line1: string;
    line2: string;
    state: string;
    city: string;
    postalCode: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CreateAccount = ({ handleSubmit }: SignUpFormProps<any>) => {
  const { t } = useTranslation("form-signUp");
  const [createUserLegacy] = useMutation(CREATE_USER_MUTATION_GQL);
  const [createUser] = useCreatePatientMutation();

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [validations, setValidations] = useState<Array<boolean>>([]);

  const [isEmailExistAlertOpen, setEmailExistAlert] = useState(false);
  const closeEmailExistAlert = () => setEmailExistAlert(false);

  const [isGenericErrorAlertOpen, setGenericErrorAlert] = useState(false);
  const closeGenericErrorAlert = () => setGenericErrorAlert(false);

  const [isEmailNotMatchingAlertOpen, setEmailNotMatchingAlert] = useState(false);
  const closeEmailNotMatchingAlert = () => setEmailNotMatchingAlert(false);
  const [emailNotMatchingReason, setEmailNotMatchingReason] = useState("");

  const { login } = useAuth();

  const pwdErrorTexts: Array<string> = [
    t("passwordRule.lowerCase"),
    t("passwordRule.upperCase"),
    t("passwordRule.digit"),
    t("passwordRule.symbol"),
    t("passwordRule.minLength"),
  ];

  const formReasonSchema = Yup.object({
    firstName: Yup.string().required(t("error.required")),
    lastName: Yup.string().required(t("error.required")),
    dob: Yup.string()
      .matches(/^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/, {
        message: t("error.dobInvalid"),
      })
      .test("minAgeTest", t("error.minAge"), (value) => {
        return minAgeTest(value);
      })
      .required(t("error.required")),
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, {
        message: t("error.emailInvalid"),
      })
      .required(t("error.required")),
    linkedPhoneNumber: Yup.string()
      .matches(/^(\+1) [0-9]{3}-[0-9]{3}-[0-9]{4}$/, {
        message: t("error.phoneInvalid"),
      })
      .required(t("error.required")),
    password: Yup.string()
      .test("passwordValidTest", "", function (value: string | null | undefined): boolean {
        const val = value;
        if (val && val !== "") {
          const validationResults: boolean[] = [];
          validationResults.push(/(?=.*[a-z])/.test(val));
          validationResults.push(/(?=.*[A-Z])/.test(val));
          validationResults.push(/(?=.*[0-9])/.test(val));
          validationResults.push(/(?=.*[!@#$&*.])/.test(val));
          validationResults.push(val.length >= 8);
          setValidations(validationResults);
          return validationResults.indexOf(false) < 0;
        }
        return false;
      })
      .required(t("error.required")),
    confirmPassword: Yup.string()
      .test(
        "confirmPasswordValidation",
        t("createAccount.confirmPasswordError"),
        function (value: string | null | undefined): boolean {
          const contextPassword = this?.parent?.password;
          return value && value !== contextPassword ? false : true;
        },
      )
      .required(t("error.required")),
    legalSex: Yup.string().required("Required"),
    address: Yup.object().shape({
      line1: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      line2: Yup.string(),
      city: Yup.string()
        .required(t("error.required"))
        .matches(/^((?![*&%$#@?]).)*$/, t("error.address.invalidChar")),
      state: Yup.string().required(t("error.required")),
      postalCode: Yup.string()
        .required(t("error.required"))
        .matches(/^\d{5}(-\d{4})?$/, t("error.address.postalCode.format")),
    }),
  });

  const signIn = async () => {
    // This will redirect.
    await login(`${window.location.origin}${routes.root}`);
  };

  const submitFormServiceLayer = async (values: SubmitProps) => {
    if (signingUp) return;

    logButtonClickEvent("Continue");
    setSigningUp(true);

    try {
      const result = await createUser({
        variables: {
          input: {
            emailAddress: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: formatDate(new Date(values.dob), "yyyy-MM-dd"),
            termsOfUseAcceptedDatetime: new Date(Date.now()).toISOString(),
            password: values.password,
            linkedPhoneNumber: values.linkedPhoneNumber
              .replace(/[()\-\s]/g, "")
              .replace(/^(\+1)/, ""),
            gender: values.legalSex as AdministrativeSex,
            address: {
              line1: values.address.line1,
              line2: values.address.line2,
              state: values.address.state as StateCode,
              city: values.address.city,
              postalCode: values.address.postalCode,
            },
          },
        },
      });

      if (result.data?.patient?.signup?.success) {
        handleSubmit(SignUpFormType.AccountCreated);
      } else {
        setGenericErrorAlert(true);
      }
    } catch (e: any) {
      if (e instanceof ApolloError) {
        if (!e.graphQLErrors || e.graphQLErrors.length === 0) {
          setGenericErrorAlert(true);
          return;
        }
        const [firstError] = e.graphQLErrors;
        const { extensions } = firstError;
        const statusCode = extensions?.status_code;

        enum ErrorCodes {
          EMAIL_EXISTS = 412,
          EMAIL_NOT_MATCHING = 409,
        }

        if (statusCode === ErrorCodes.EMAIL_EXISTS) {
          setEmailExistAlert(true);
          return;
        }

        if (statusCode === ErrorCodes.EMAIL_NOT_MATCHING) {
          const reason = extensions?.reason;
          setEmailNotMatchingAlert(true);
          setEmailNotMatchingReason(reason);
          return;
        }
      }

      setGenericErrorAlert(true);
    }
    setSigningUp(false);
  };

  const submitFormLegacy = async (values: SubmitProps) => {
    if (signingUp) return;

    logButtonClickEvent("Continue");
    setSigningUp(true);

    try {
      const passwordObj = encryptPassword(values.password);

      const { data: response } = await createUserLegacy({
        variables: {
          input: {
            emailAddress: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: values.dob,
            termsOfUseAcceptedDateTime: new Date(Date.now()).toISOString(),
            password: passwordObj.password,
            keyArrayBuffer: passwordObj.key,
            ivArrayBuffer: passwordObj.iv,
            linkedPhoneNumber: values.linkedPhoneNumber.replace(/-/g, ""),
            gender: values.legalSex as AdministrativeSex,
            address: {
              line1: values.address.line1,
              line2: values.address.line2,
              state: values.address.state as StateCode,
              city: values.address.city,
              postalCode: values.address.postalCode,
            },
          },
        },
      });

      if (response?.createUser?.errors && response?.createUser?.errors.length > 0) {
        const userExists = response.createUser.errors[0].code === 422;
        if (userExists) {
          setEmailExistAlert(true);
        } else {
          setGenericErrorAlert(true);
        }
      } else {
        handleSubmit(SignUpFormType.AccountCreated);
      }
    } catch (e: any) {
      setGenericErrorAlert(true);
    }
    setSigningUp(false);
  };

  const submitForm = async (values: SubmitProps) => {
    if (hasFeatureFlagEnabled("signup_slayer_enabled")) {
      await submitFormServiceLayer(values);
    } else {
      await submitFormLegacy(values);
    }
  };

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.ONBOARDING_NEW,
      source: AnalyticsSource.ONBOARDING_SIGN_UP,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        password: "",
        linkedPhoneNumber: "",
        confirmPassword: "",
        legalSex: "",
        address: { line1: "", line2: "", city: "", state: "", postalCode: "" },
      }}
      validationSchema={formReasonSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting, isValid, setFieldValue, setFieldTouched }) => (
        <FormContentContainer>
          <Form>
            <Title>{t("createAccount.title")}</Title>
            <div css={[layout.spacedChildrenVertical("standard")]}>
              <TextField
                label={t("createAccount.firstName")}
                placeholder={t("createAccount.firstName")}
                name="firstName"
                type="text"
              />
              <TextField
                label={t("createAccount.lastName")}
                placeholder={t("createAccount.lastName")}
                name="lastName"
                type="text"
              />
              <FormattedTextField
                label={t("createAccount.dateOfBirth")}
                placeholder={t("createAccount.dobPlaceholder")}
                name="dob"
                type="text"
                mask="99/99/9999"
              />
              <FormattedTextField
                label={t("createAccount.linkedPhoneNumber")}
                placeholder={t("createAccount.phonePlaceholder")}
                name="linkedPhoneNumber"
                type="phone"
                mask="+1 999-999-9999"
              />

              <PickerField
                css={layout.flexVertical}
                label={t("patientIdentity.legalSex")}
                placeholder={t("patientIdentity.legalSexPlaceholder")}
                name="legalSex"
                options={[
                  {
                    label: "Female",
                    value: "FEMALE",
                  },
                  {
                    label: "Male",
                    value: "MALE",
                  },
                ]}
              />
              <AddressForm />

              <TextField
                css={layout.margin("none")}
                label={t("createAccount.email")}
                placeholder={t("createAccount.email")}
                name="email"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("email", e.currentTarget.value.trim());
                }}
                onBlur={() => {
                  setFieldTouched("email");
                }}
              />

              <TextField
                label={t("createAccount.password")}
                placeholder={t("createAccount.passwordPlaceholder")}
                name="password"
                onFocus={() => {
                  setPasswordFocused(true);
                }}
                onBlur={() => {
                  setPasswordFocused(false);
                  setFieldTouched("password");
                }}
                type={showPassword ? "text" : "password"}
                after={
                  <button type="button" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Eye /> : <EyeOff />}
                  </button>
                }
              />
              {passwordFocused && (
                <PasswordValidations
                  title={t("error.passwordErrorTitle")}
                  validationMappings={pwdErrorTexts}
                  validations={validations}
                />
              )}
              <TextField
                label={t("createAccount.confirmPassword")}
                placeholder={t("createAccount.passwordConfirmPlaceholder")}
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                after={
                  <button
                    type="button"
                    onClick={() => setConfirmShowPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Eye /> : <EyeOff />}
                  </button>
                }
              />
              <CheckBox
                css={layout.margin("gutter", "skip", "expanded")}
                labelBefore={false}
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
                label={
                  <Trans
                    components={[
                      <Link.Anchor
                        href={TERMS_LINK}
                        target="_blank"
                        onClick={() => logButtonClickEvent("Terms of Service and Privacy Policy")}
                      />,
                    ]}
                    i18nKey="createAccount.privacyPolicy"
                    t={t}
                  />
                }
              />
            </div>
            <FormContentContainer.ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!isValid || !termsChecked}
                type="submit"
                isLoading={isSubmitting}
              >
                {t("continue")}
              </Button>
            </FormContentContainer.ButtonGroup>
            <Alert
              close={closeEmailExistAlert}
              footer={
                <>
                  <Button fullWidth="flex" onClick={() => setEmailExistAlert(false)}>
                    {t("dialog.emailExists.confirmLabel")}
                  </Button>
                  <Button
                    variant="secondary"
                    fullWidth="flex"
                    onClick={() => {
                      signIn();
                    }}
                  >
                    {t("dialog.emailExists.cancelLabel")}
                  </Button>
                </>
              }
              title={t("dialog.emailExists.title")}
              isOpen={isEmailExistAlertOpen}
            >
              {t("dialog.emailExists.subtitle")}
            </Alert>

            <Alert
              close={closeGenericErrorAlert}
              footer={
                <Button fullWidth="flex" onClick={() => setGenericErrorAlert(false)}>
                  {t("dialog.errorDialog.confirmLabel")}
                </Button>
              }
              title={t("dialog.errorDialog.title")}
              isOpen={isGenericErrorAlertOpen}
            >
              {t("dialog.errorDialog.subtitle")}
            </Alert>

            <Alert
              close={closeEmailNotMatchingAlert}
              footer={
                <Button fullWidth="flex" onClick={() => setEmailNotMatchingAlert(false)}>
                  {t("dialog.emailNotMatching.confirmLabel")}
                </Button>
              }
              title={t("dialog.emailNotMatching.title")}
              isOpen={isEmailNotMatchingAlertOpen}
            >
              {emailNotMatchingReason || t("dialog.emailNotMatching.subtitle")}
            </Alert>
          </Form>
        </FormContentContainer>
      )}
    </Formik>
  );
};

export default CreateAccount;
